import * as React from 'react';
import { withPlainLayout, LayoutProps } from '../../components/Layout';

import Helmet from 'react-helmet';
import styled from '../../styled-components';
import { LinkArrow } from '../../components/ui';
import { media } from '../../utilities';

const ThankYouPageContent = styled.div`
    background-color: ${({ theme }) => theme.colors.deepBlue.color};
    color: white;
    height: 100vh;
`;

const ThankYouPageMessage = styled.div`
    max-width: 320px;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    color: ${({ theme }) => theme.colors.deepBlue.color};
    padding: 3rem;
    h1 {
        font-size: ${({ theme }) => theme.fontNew.primary.size.level5.size};

        ${media.m`
            font-size: ${({ theme }) => theme.fontNew.primary.size.level4.size};
        `}
    }

    ${media.m`
        max-width: 400px;
    `}
`;

const ThankYouPage = (props: LayoutProps) => {
    return (
        <>
            <Helmet>
                <title>We Make Waves - Thanks for booking a call.</title>
                <meta
                    name="description"
                    content="Thanks for booking a call with us at We Make Waves"
                />
            </Helmet>

            <ThankYouPageContent>
                <ThankYouPageMessage>
                    <h1>Thanks, we'll be in touch!</h1>

                    <LinkArrow
                        to="/entrepreneur-with-an-idea-for-innovative-web-or-mobile-app"
                        color="deepBlue"
                        arrowColor="electricBlue"
                    >
                        Take me back
                    </LinkArrow>
                </ThankYouPageMessage>
            </ThankYouPageContent>
        </>
    );
};

export default withPlainLayout(ThankYouPage);
